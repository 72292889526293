<template>
    <div class="main-layout">
        <sidebar></sidebar>
        <section class="content-page">
            <router-view>
            </router-view>
        </section>
    </div>
</template>

<script>
import Sidebar from '../components/Sidebar.vue'
export default {
    name: 'MainLayout',
    components: {
        Sidebar
    }
}
</script>

<style lang="scss" scoped>
.main-layout {
    display: flex;
}
.content-page {
    padding: 4rem;
    width: 100%;
    height: 100vh;
    overflow: auto;
}
</style>
