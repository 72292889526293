<template>
    <div class="side-bar">
        <div>
            <div
                @click="changePath(name.path)"
                v-for="name in this.sidebar"
                :key="name.name"
                :class="setActiveMenu(name.path)">
                {{name.name}}
            </div>
        </div>
        <div class="d-flex d-flex-justify-center">
            <v-btn @click="logout()" color="error">
                Logout
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            role: 'superAdmin',
            path: '/',
            defaultSidebar: [
                {
                    name: 'Student',
                    path: '/students'
                },
                {
                    name: 'Student Mobile',
                    path: '/mobile/students'
                },
                {
                    name: 'Class',
                    path: '/ep-classes'
                }
            ],
            superAdminSidebar: [
                {
                    name: 'Branch',
                    path: '/branches'
                },
                {
                    name: 'Course',
                    path: '/ep-courses'
                },
                {
                    name: 'Staff',
                    path: '/staff'
                },
                {
                    name: 'Group Course',
                    path: '/group-courses'
                },
                {
                    name: 'Last time booking',
                    path: '/last-time-booking'
                }
            ],
            sidebar: []
        }
    },
    computed: {

    },
    methods: {
        createSidebar () {
            if (this.role === 'admin') {
                this.sidebar = this.defaultSidebar
            } else if (this.role === 'superAdmin') {
                this.sidebar = [...this.superAdminSidebar, ...this.defaultSidebar]
            }
        },

        setActiveMenu (path) {
            let style = 'menu '
            if (this.path.includes(path)) {
                style = `${style} menu-active`
            }
            return style
        },

        changePath (path) {
            if (this.path !== path) {
                this.$router.push({ path: path })
                this.path = path
            }
        },

        logout () {
            localStorage.setItem('jwt', null)
            localStorage.setItem('username', null)
            this.$router.push({ name: 'Login' }, () => {})
        }
    },

    mounted () {
        const isSuperAdmin = localStorage.getItem('isSuperAdmin')
        if (isSuperAdmin === 'true') {
            this.role = 'superAdmin'
        } else {
            this.role = 'admin'
        }
        this.createSidebar()
        this.path = this.$route.path
    }
}
</script>

<style lang="scss" scoped>
.side-bar {
    min-width: 300px;
    width: 300px;
    height: 100vh;
    background-color: #502B94;
    color: #fff;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .menu {
        padding: 12px 24px;
        height: 40px;
        cursor: pointer;
        margin-bottom: 8px;
    }

    .menu:hover {
        background-color: #fff;
        opacity: 0.9;
        color: #502B94;
        border-radius: 20px;
    }

    .menu-active {
        border-radius: 20px;
        background-color: #fff;
        color: #502B94;
    }
}
</style>
